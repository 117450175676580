<template>
  <el-main>
    <el-row type="flex" justify="space-between">
      <el-col :span="12">
        <BreadCrumb />
      </el-col>
      <el-col :span="12" class="btn-group">
        <el-button type="primary" @click="submitForm('form')">Save</el-button>
        <el-button @click="$router.push('/work/customers')">Back<i class="el-icon-back"></i></el-button>
        <el-button @click="refresh"><i class="el-icon-refresh-right el-icon--right"></i> Refresh</el-button>
      </el-col>
    </el-row>
    <el-row style="margin-top: 10px">
      <el-form label-suffix=":" class="customer-form" :model="form" :rules="rules" ref="form" label-width="150px">
        <el-form-item label="Name" prop="name" required>
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="Country" prop="country">
          <el-select v-model="form.country">
            <el-option label="Australia" value="Australia"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Secret Key" prop="secretKey">
          <el-input disabled v-model="form.secretKey"></el-input>
          <el-button style="margin-left: 10px;" width="100px;" @click="generateUUID"><i class="el-icon-bank-card"></i>Generate</el-button>
        </el-form-item>
        <el-form-item label="Last Activaty" prop="lastActivity">
          <div>{{ getTime(form.lastActivity) }}</div>
        </el-form-item>
        <el-form-item label="Licences">
          <div>
            <el-table stripe
              class="licences-table"
              border v-loading="loading"
              @sort-change="handleColumn"
              @selection-change="handleSelectionChange"
              style="margin-top: 10px"
              :data="list"
              height="500px"
              :sort="sort">
              <el-table-column
                type="selection"
                width="55" />
              <el-table-column prop="_id" width="220" label="Licence ID">
              </el-table-column>
              <el-table-column prop="expiredAt" width="135" label="Expired Date" sortable="custom" >
                <template slot-scope="scope">
                  {{ getDate(scope.row.expiredAt) }}
                </template>
              </el-table-column>
              <el-table-column prop="issuedAt" width="135" label="Issued Date" sortable="custom" >
                <template slot-scope="scope">
                  {{ getDate(scope.row.issuedAt) }}
                </template>
              </el-table-column>
              <el-table-column prop="assigned" width="100" label="Assigned?">
                <template slot-scope="scope">
                  <i v-if="scope.row.state === 'assigned'" class="el-icon-check"></i>
                </template>
              </el-table-column>
              <el-table-column prop="workstation.ip" width="150" label="IP Address" sortable="custom">
              </el-table-column>
              <el-table-column prop="workstation.hostName" width="150" label="Host Name" sortable="custom">
              </el-table-column>
              <el-table-column prop="workstation.machineId" width="220" label="Machine ID">
              </el-table-column>
              <el-table-column prop="workstation.activationCode" width="220" label="Activation Code">
              </el-table-column>
              <el-table-column label="App" width="150">
                <template slot-scope="scope">
                  {{scope.row.workstation && scope.row.workstation.appVersion}}
                </template>
              </el-table-column>
              <el-table-column label="Model" width="150">
                <template slot-scope="scope">
                  {{scope.row.workstation && scope.row.workstation.modelVersion}}
                </template>
              </el-table-column>
              <el-table-column prop="remarks" label="Remarks" width="250" >
              </el-table-column>
            </el-table>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="pageSize"
              layout="prev, pager, next, total"
              :total="total">
            </el-pagination>
            <el-row type="flex" justify="space-between">
              <el-col :span="24">
                <span>Selected({{selects.length}}):</span>
                <el-button
                  :disabled="!selects.length"
                  style="margin-right: 10px;" width="100px;" type="primary"
                  @click="deleteLicences"><i class="el-icon-delete"></i>Remove</el-button>
                <el-button 
                  :disabled="!selects.length"
                   style="margin-right: 10px;" width="100px;" type="primary"
                   @click="revokeLicences">Revoke<i class="el-icon-refresh-right el-icon--right"></i></el-button>
                <el-button style="margin-right: 10px;" width="100px;" 
                  :disabled="selects.length !== 1"
                  @click="reNew">Renew
                  <i class="el-icon-refresh-right el-icon--right"></i></el-button>
                <el-button type="primary" plain @click="dialogFormVisible = true"><i class="el-icon-plus"></i> Add</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
        <el-form-item label="App Repository">
          <div>
            <el-table 
              stripe
              height="300px"
              @sort-change="handleColumn"
              style="margin-top: 10px" border
              v-loading="loading"
              :data="appRepositorys">
              <el-table-column prop="version" label="Version" sortable="custom">
              </el-table-column>
              <el-table-column prop="downloadedAt" label="Downloaded Date">
                <template slot-scope="scope">
                  {{ getDate(scope.row.downloadedAt) }}
                </template>
              </el-table-column>
              <el-table-column prop="releasedAt" label="Released Date">
                <template slot-scope="scope">
                  {{ getDate(scope.row.releasedAt) }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
        <el-form-item label="Model Repository">
          <div>
            <el-table 
              height="300px"
              stripe @sort-change="handleColumn"
              style="margin-top: 10px"
              border v-loading="loading"
              :data="modelRepositorys">
              <el-table-column prop="version" label="Version" sortable="custom">
              </el-table-column>
              <el-table-column prop="downloadedAt" label="Downloaded Date">
                <template slot-scope="scope">
                  {{ getDate(scope.row.downloadedAt) }}
                </template>
              </el-table-column>
              <el-table-column prop="releasedAt" label="Released Date">
                <template slot-scope="scope">
                  {{ getDate(scope.row.releasedAt) }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
      </el-form>
    </el-row>
    <AddLicencesDialog ref="AddLicencesDialog" @refresh="getList" :dialogFormVisible.sync="dialogFormVisible"></AddLicencesDialog>
  </el-main>
</template>
<script>
import BreadCrumb from '../components/BreadCrumb.vue'
import AddLicencesDialog from '../components/AddLicencesDialog.vue'
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  components: { BreadCrumb, AddLicencesDialog },
  data () {
    const query = this.$route.query
    return {
      form: {
        name: '',
        country: '',
        secretKey: ''
      },
      rules: {
        name: [
          { required: true, trigger: 'blur' }
        ],
        country: [
          { required: true, trigger: 'blur' }
        ],
        secretKey: [
          { required: true, trigger: 'blur' }
        ]
      },
      loading: false,
      appLoading: false,
      modelLoading: false,
      dialogFormVisible: false,
      selects: [],
      list: [],
      customer: {},
      total: 0,
      sort: {
        prop: query.sort || 'createdAt',
        order: query.order || 'descending'
      },
      appRepositorys: [],
      modelRepositorys: [],
      search: query.searchStr ? Number(query.searchStr) : '',
      pageSize: query.pageSize ? Number(query.pageSize) : 10,
      currentPage: query.currentPage ? Number(query.currentPage) : 1,
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: async function () {
    this.refresh()
  },
  methods: {
    refresh () {
      this.getList()
      this.getObject()
      this.getAppLogs()
      this.getModelLogs()
    },
    handleSelectionChange(val) {
      this.selects = val
    },
    back () {
      this.$router.go(-1)
    },
    generateUUID () {
      var d = new Date().getTime();
      if (window.performance && typeof window.performance.now === "function") {
        d += performance.now()
      }
      var uuid = 'xxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      });
      this.form.secretKey = uuid
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form._id = this.$route.params.id
          this.$store.httpDispatch({})('updateCustomer', this.form).then((res) => {
            this.getObject()
          }).catch((err) => {
            console.log(err)
            this.$notify.error({ title: 'Error', message: `[${err.data.code}] ${err.data.msg}`, duration: 5000 })
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async reNew () {
      if (this.selects.length !== 1) {
        return
      }
      let result = null
      try {
        result = await this.$store.httpDispatch({})('addLicence', {
          amount: 1,
          expiredAt: this.selects[0].expiredAt,
          remarks: this.selects[0].remarks,
          customerId: this.$route.params.id
        })
        const { code, data } = result.data
        if (code === 0) {
          this.getList()
          this.$notify.success({ title: 'Successful operation', duration: 5000 })
        }
      } catch (err) {
        console.log(err)
        this.$notify.error({ title: 'Error', message: `[${err.data.code}] ${err.data.msg}`, duration: 5000 })
      }
    },
    getDate (createdAt) {
      return moment(createdAt).format('YYYY-MM-DD')
    },
    getTime (createdAt) {
      return moment(createdAt).format('YYYY-MM-DD HH:mm:ss')
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleColumn (column) {
      this.sort = column
      this.getList()
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.getList()
    },
    async getObject () {
      this.loading = true
      let result = {}
      try {
        result = await this.$store.httpDispatch({})('getCustomer', {
          _id: this.$route.params.id
        })
      } catch (err) {
        console.log(err)
        this.$notify({
          title: 'Request failed',
          type: 'error'
        })
        this.loading = false
        return false
      }
      const { code, data } = result.data
      if (code === 0) {
        const customer = data.customer
        this.form = {
          name: customer.name,
          country: customer.country,
          secretKey: customer.secretKey,
          lastActivity: customer.lastActivity
        }
      }
      this.loading = false
    },
    async getList () {
      this.loading = true
      this.selects = []
      let result = {}
      let query = {
        pageSize: this.pageSize,
        currentPage: this.currentPage,
        searchStr: this.search,
        sort: this.sort.prop,
        order: this.sort.order,
        customerId: this.$route.params.id
      }
      // this.$router.replace({
      //   path: this.$route.path,
      //   query
      // })
      try {
        result = await this.$store.httpDispatch({})('getLicenceList', query)
      } catch (err) {
        console.log(err)
        this.$notify({
          title: 'Request failed',
          type: 'error'
        })
        this.loading = false
        return false
      }
      const { code, data } = result.data
      if (code === 0) {
        this.list = data.licences
        this.total = data.total
      }
      this.loading = false
    },
    async getModelLogs () {
      this.modelLoading = true
      let result = {}
      let query = {
        type: 'model',
        customerId: this.$route.params.id
      }
      try {
        result = await this.$store.httpDispatch({})('getDownloadLogs', query)
      } catch (err) {
        this.$notify({
          title: 'Request failed',
          type: 'error'
        })
        this.modelLoading = false
        return false
      }
      const { code, data } = result.data
      if (code === 0) {
        this.modelRepositorys = data.downloads
      }
      this.modelLoading = false
    },
    async getAppLogs () {
      this.appLoading = true
      let result = {}
      let query = {
        type: 'app',
        customerId: this.$route.params.id
      }
      try {
        result = await this.$store.httpDispatch({})('getDownloadLogs', query)
      } catch (err) {
        this.$notify({
          title: 'Request failed',
          type: 'error'
        })
        this.appLoading = false
        return false
      }
      const { code, data } = result.data
      if (code === 0) {
        this.appRepositorys = data.downloads
      }
      this.appLoading = false
    },
    async deleteLicences () {
      this.loading = true
      let result = {}
      let selects = this.selects.map((sl) => sl._id)
      try {
        result = await this.$store.httpDispatch({})('deleteLicence', {
          selects
        })
        const { code, data } = result.data
        if (code === 0) {
          this.$notify.success({ title: 'Successful operation', duration: 5000 })
        } else {
          return  this.$notify({
            title: data.msg,
            type: 'Error'
          })
        }
      } catch (err) {
        console.log(err)
        this.$notify({
          title: 'Request failed',
          type: 'error'
        })
        this.loading = false
        return false
      }
      this.getList()
      this.loading = false
    },
    async revokeLicences () {
      this.loading = true
      let result = {}
      let selects = this.selects.map((sl) => sl._id)
      try {
        result = await this.$store.httpDispatch({})('revokeLicences', {
          selects
        })
        const { code, data } = result.data
        if (code === 0) {
          this.$notify.success({ title: 'Successful operation', duration: 5000 })
        } else {
          return  this.$notify({
            title: data.msg,
            type: 'Error'
          })
        }
      } catch (err) {
        console.log(err)
        this.$notify({
          title: 'Request failed',
          type: 'error'
        })
        this.loading = false
        return false
      }
      this.getList()
      this.loading = false
    }
  }
}
</script>
<style lang="less">
.customer-form {
  .el-form-item__content {
    text-align: left
  }
  .licences-table {
    width: 100%;
    overflow-x: auto;
  }
  .el-input {
    width: 200px;
  }
}
</style>
