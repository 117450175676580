

<template>
  <div>
    <el-dialog
      @close="closeDialog"
      :visible="dialogFormVisible"
      width="600px"
      center>
      <el-form label-suffix=":" :model="form" :rules="rules" ref="form" label-width="100px">
        <el-form-item label="amount" prop="amount" required>
          <el-input v-model="form.amount"></el-input>
        </el-form-item>
        <el-form-item label="Expire Date" prop="expiredAt">
          <el-date-picker type="date" placeholder="Choose Date" v-model="form.expiredAt"></el-date-picker>
        </el-form-item>
        <el-form-item label="Remarks" prop="remarks">
          <el-input type="textarea" v-model="form.remarks"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="closeDialog">Cancel</el-button>
          <el-button type="primary" @click="submitForm('form')"><i class="el-icon-plus"></i> Add</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ['dialogFormVisible'],
  data() {
    return  {
      form: {
        amount: '',
        expiredAt: '',
        remarks: ''
      },
      rules: {
        amount: [
          { required: true, trigger: 'blur' }
        ],
        expiredAt: [
          { required: true, trigger: 'blur' }
        ]
      }
    }
  },
  beforeMount () {
  },
  methods: {
    closeDialog () {
      this.form = {
        amount: '',
        expiredAt: '',
        remarks: ''
      }
      this.$emit('update:dialogFormVisible', false)
      this.$refs['form'].resetFields()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.customerId = this.$route.params.id
          this.$store.httpDispatch({})('addLicence', this.form).then((res) => {
            this.closeDialog()
            this.$emit('refresh')
          }).catch((err) => {
            console.log(err)
            this.$notify.error({ title: 'Error', message: `[${err.data.code}] ${err.data.msg}`, duration: 5000 })
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    }
  }
}
</script>